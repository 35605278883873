<template>
  <div>
    <a-modal :visible="visible" title="Kullanıcı Detayları" style="top: 20px" @ok="closeModal" @cancel="closeModal">
      <a-form layout="vertical" :model="userData">

        <a-form-item label="Ad & Soyad">
          <a-input v-model:value="userData.name" placeholder="ad ve soyad"/>
        </a-form-item>

        <a-form-item label="E-Posta">
          <a-input v-model:value="userData.email" placeholder="e-posta adresi"/>
        </a-form-item>

        <a-form-item label="Telefon">
          <a-input v-model:value="userData.phone" placeholder="kullanıcı telefon" type="number"/>
        </a-form-item>

        <a-form-item label="Departman">
          <a-select v-model:value="userData.department">
            <a-select-option v-if="authUser.departmentInfo === 'super'" value="super">Root Yönetici</a-select-option>
            <a-select-option v-if="authUser.departmentInfo === 'super' || authUser.departmentInfo === 'accouting'"
                             value="accouting">Muhasebe Birimi
            </a-select-option>
            <a-select-option v-if="authUser.departmentInfo === 'super' || authUser.departmentInfo === 'sales'"
                             value="sales">Satış Birimi
            </a-select-option>
            <a-select-option v-if="authUser.departmentInfo === 'super' || authUser.departmentInfo === 'support'"
                             value="support">Destek Birimi
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Departman Yöneticisi">
          <a-select v-model:value="userData.departmentSuperUser">
            <a-select-option value="true">Evet</a-select-option>
            <a-select-option value="false">Hayır</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Şifre">
          <a-input v-model:value="userData.password" placeholder="yeni kullanıcı şifresi"/>
        </a-form-item>

        <a-form-item label="Google Authenticator QR">
          <a-input v-model:value="userData.qrVerify" placeholder="QR kodunu doğrulayın."/>

          <img style="margin-top: 10px" :src="userData.qrSecretIMG" alt="qr_image">

        </a-form-item>
      </a-form>

      <template #footer>
        <a-button @click="getQR" :loading="qrLoading">QR Yenile</a-button>
        <a-button key="back" @click="closeModal">Kapat</a-button>
        <a-button key="submit" :loading="loading" type="primary" @click="submit" :disabled="submitBtn">Kaydet</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {defineComponent, reactive, ref} from 'vue';
import instance from "@/http/axios";
import crypto from "crypto";
import {message} from "ant-design-vue";

export default defineComponent({
  data() {
    return {
      loading: false,
      qrLoading: false
    }
  },
  props: ["visible", "authUser"],
  setup() {
    const userData = reactive({
      name: "",
      email: "",
      phone: "",
      department: "",
      password: "",
      departmentSuperUser: "",
      qrSecret: "",
      qrSecretIMG: "",
      qrVerify: ""
    });
    return {
      userData
    };
  },
  computed: {
    submitBtn() {
      return !(this.userData.name !== "" && this.userData.email !== "" && this.userData.phone !== "" && this.userData.department !== "" && this.userData.password !== "" && this.userData.departmentSuperUser !== "")
    }
  },
  methods: {
    submit() {
      let formData = new FormData();
      this.loading = true;

      formData.append('name', this.userData.name)
      formData.append('email', this.userData.email)
      formData.append('phone', this.userData.phone)
      formData.append('department', this.userData.department)
      formData.append('departmentSuperUser', this.userData.departmentSuperUser)
      formData.append('qrSecret', this.userData.qrSecret)
      formData.append('qrVerify', this.userData.qrVerify)
      formData.append('qrIMG', this.userData.qrSecretIMG)
      if (this.userData.password.length > 0)
        formData.append('password', crypto.createHash('md5').update(this.userData.password).digest('hex'))

      instance.post('/user/super/addNewUser', formData).then(response => {
        if (response.data.code === 200) {
          message.success(response.data.msg)

          this.userData.password = ""
          this.userData.name = ""
          this.userData.email = ""
          this.userData.phone = ""
          this.userData.department = ""
          this.userData.departmentSuperUser = ""

          // QR Yenileme İşlemi Yap
          this.getQR()
        } else {
          message.error(response.data.msg)
        }

        this.loading = false
      })

    },
    closeModal() {
      this.$emit('visibleChange', false)
    },
    getQR() {
      this.qrLoading = true
      instance.get('/google/get-code').then(response => {
        this.userData.qrVerify = ""
        this.userData.qrSecretIMG = response.data.code;
        this.userData.qrSecret = response.data.secret;
        this.qrLoading = false
      })
    }
  },
  watch: {
    visible() {
      if (this.visible) {
        this.getQR()
        this.userData.password = ""
        this.userData.name = ""
        this.userData.email = ""
        this.userData.phone = ""
        this.userData.department = ""
        this.userData.departmentSuperUser = ""
      }
    }
  }
});
</script>
<template>
  <Default>
    <template v-slot:breadcrumb>
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>Anasayfa</a-breadcrumb-item>
        <a-breadcrumb-item>Kullanıcılar</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
    <template v-slot:content>
      <div class="content">
        <div class="content-head">
          <h3>Tüm Kullanıcılar</h3>

          <a-button @click="newUserModal" type="primary">
            <PlusOutlined style="font-size: 11px" /> Yeni Kullanıcı Ekle
          </a-button>

        </div>
        <UsersTable :newUserModalVisible="newUserModalVisible" :authUser="authUser" @visibleChange="newUserModalVisible = $event" />
      </div>
    </template>
    <template v-slot:mTitle>Kullanıcılar | Bill</template>
  </Default>
</template>

<script>
import Default from "@/components/_layouts/Default";
import UsersTable from "@/views/Users/components/UsersTable";
import { PlusOutlined } from "@ant-design/icons-vue"

export default {
  name: "Home",
  data(){
    return {
      newUserModalVisible: false,
      authUser: this.$store.getters.authUserInfo
    }
  },
  methods: {
    newUserModal(){
      this.newUserModalVisible = true
    }
  },
  components: {
    Default, UsersTable,
    PlusOutlined
  }
}
</script>

<style scoped>
.content {
  padding: 24px;
  background: #fff;
  min-height: 360px;
}

.content-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>
<template>
  <div>
    <a-modal :visible="visible" title="Kullanıcı Detayları" style="top: 20px" @ok="closeModal" @cancel="closeModal">
      <a-form layout="vertical" :model="userData">
        <a-form-item label="Ad & Soyad">
          <a-input v-model:value="userData.name" placeholder="ad ve soyad"/>
        </a-form-item>
        <a-form-item label="E-Posta">
          <a-input v-model:value="userData.email" placeholder="e-posta adresi"/>
        </a-form-item>
        <a-form-item label="Telefon">
          <a-input v-model:value="userData.phone" placeholder="kullanıcı telefon" type="number"/>
        </a-form-item>
        <a-form-item label="Departman">
          <a-select v-model:value="userData.department">
            <a-select-option v-if="authUser.departmentInfo === 'super'" value="super">Root Yönetici</a-select-option>
            <a-select-option value="accouting">Muhasebe Birimi</a-select-option>
            <a-select-option value="sales">Satış Birimi</a-select-option>
            <a-select-option value="support">Destek Birimi</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Departman Yöneticisi">
          <a-select v-model:value="userData.departmentSuperUser">
            <a-select-option value="true">Evet</a-select-option>
            <a-select-option value="false">Hayır</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Durumu">
          <a-select v-model:value="userData.active">
            <a-select-option value="true">Aktif</a-select-option>
            <a-select-option value="false">Deaktif</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="QR Code">
          <img :src="userData.qrIMG" alt="qr_image">
        </a-form-item>
        <a-alert style="margin-bottom: 10px" message="Şifreyi değiştirmek istiyorsanız yazın" type="warning"
                 show-icon/>
        <a-form-item label="Şifre">
          <a-input v-model:value="userData.password" placeholder="yeni kullanıcı şifresi"/>
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button key="back" @click="closeModal">Kapat</a-button>
        <a-button key="submit" :loading="loading" type="primary" @click="submit" :disabled="submitBtn">Kaydet</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {defineComponent, reactive, ref} from 'vue';
import instance from "@/http/axios";
import crypto from "crypto";
import {message} from "ant-design-vue";

export default defineComponent({
  data() {
    return {
      loading: false,
    }
  },
  props: ["visible", "oid", "authUser"],
  setup() {
    const userData = reactive({
      name: "",
      email: "",
      phone: "",
      department: "",
      password: "",
      departmentSuperUser: "",
      active: "",
      qrIMG: ""
    });
    return {
      userData
    };
  },
  computed: {
    submitBtn() {
      return !(this.userData.name.length > 0 && this.userData.email.length > 0 && this.userData.phone.length > 0)
    }
  },
  methods: {
    submit() {
      let formData = new FormData();
      this.loading = true;

      formData.append('userOID', this.oid)
      formData.append('name', this.userData.name)
      formData.append('email', this.userData.email)
      formData.append('phone', this.userData.phone)
      formData.append('department', this.userData.department)
      formData.append('departmentSuperUser', this.userData.departmentSuperUser)
      formData.append('active', this.userData.active)
      if (this.userData.password.length > 0)
        formData.append('password', crypto.createHash('md5').update(this.userData.password).digest('hex'))

      instance.post('/user/super/changeUserInfo', formData).then(response => {
        if (response.data.code === 200) message.success(response.data.msg)
        else message.error(response.data.msg)

        this.loading = false
      })

    },
    closeModal() {
      this.$emit('visibleChange', false)
    }
  },
  watch: {
    visible() {
      if (this.visible) {
        this.userData.password = ""
        instance.get('/user/info?id=' + this.oid).then(response => {
          this.userData.name = response.data.name
          this.userData.email = response.data.email
          this.userData.phone = response.data.phone ?? ""
          this.userData.department = response.data.departmentInfo
          this.userData.departmentSuperUser = response.data.departmentSuperUser ? "true" : "false"
          this.userData.active = response.data.active ? "true" : "false"
          this.userData.qrIMG = response.data.qr.img
        })

      }
    }
  }
});
</script>
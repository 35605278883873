<template>
  <a-table
      :columns="columns"
      :row-key="record => record._id['$oid']"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
  >
    <template
        #inputSearch="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
    >
      <div style="padding: 8px">
        <a-input
            :ref="c => (nameSearchTextInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
        />
        <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          <template #icon>
            <SearchOutlined/>
          </template>
          Search
        </a-button>
        <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
        >
          Reset
        </a-button>
      </div>
    </template>
    <template #filterIcon="filtered">
      <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
    </template>

    <template #name="{ text }"> {{ text }}</template>
    <template #email="{ email }"> {{ email }}</template>
    <template #phones="{ phone }"> {{ phone }}</template>
    <template #department="{ text }"> {{
        text === "super" ? "Root Yönetici" : text === "accouting" ? "Muhasebe" : text === "support" ? "Destek" : text === "sales" ? "Satiş" : null
      }}
    </template>
    <template #departmentSuper="{ text }"> {{ text ? "Evet" : "Hayır" }}</template>

    <template v-if="authUser.departmentSuperUser" #action="{ record }">
      <a-button type="primary" @click="userDetail(record._id['$oid'])">Detay</a-button>
    </template>

  </a-table>
  <UserDetailModal :oid="userDetailModalOID" :visible="userDetailModalVisible" :authUser="authUser" @visibleChange="userDetailModalVisible = $event"/>
  <NewUserModal :visible="newUserModalVisible" :authUser="authUser" @visibleChange="this.$emit('visibleChange',$event)"/>
</template>
<script>
import instance from "@/http/axios";
import {SearchOutlined} from "@ant-design/icons-vue";
import UserDetailModal from "@/views/Users/components/UserDetailModal";
import NewUserModal from "@/views/Users/components/NewUserModal";

export default {
  components: {
    SearchOutlined,
    UserDetailModal, NewUserModal
  },
  props: ["authUser","newUserModalVisible"],
  data() {
    return {
      data: [],
      params: {},

      userDetailModalVisible: false,
      userDetailModalOID: "",

      nameSearchText: "",
      nameSearchTextInput: null,
      nameSearchedColumn: "",

      pagination: {},
      loading: false,
      columns: []
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    userDetail(oid) {
      this.userDetailModalVisible = true
      this.userDetailModalOID = oid
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = {...this.pagination};
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      });
    },
    fetch(params = {}, searchColumn = null, searchColumnText = null) {
      this.loading = true;
      this.params = params;
      instance
          .get("/user/super/getUser", {
            params: {
              ...params,
              searchColumn,
              searchColumnText
            }
          })
          .then(response => {
            this.data = [];
            return response;
          })
          .then(response => {
            let totalPage = response.data.totalCustomerData;
            const pagination = {...this.pagination};
            pagination.total = totalPage;

            response.data.data.forEach(v => {
              if (v.departmentInfo !== "super" || this.authUser.departmentInfo === "super")
                if ((v.departmentInfo === this.authUser.departmentInfo && this.authUser.departmentSuperUser === true) || this.authUser.departmentInfo === "super")
                  this.data.push({
                    _id: {
                      $oid: v._id["$oid"]
                    },
                    name: v.name,
                    mail: v.email,
                    phone: v.phone,
                    department: v.departmentInfo,
                    departmentSuper: v.departmentSuperUser
                  });

            });

            this.pagination = pagination;
            this.loading = false;
          });
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();

      this.nameSearchText = selectedKeys[0];
      this.nameSearchedColumn = dataIndex;
      this.fetch(this.params, this.nameSearchedColumn, this.nameSearchText);
      this.$forceUpdate();
    },
    handleReset(clearFilters) {
      clearFilters();
      this.nameSearchText = "";
    }
  },
  created() {
    this.columns = [
      {
        title: "Kullanıcı Adı",
        dataIndex: "name",
        sorter: true,
        width: "20%",
        slots: {
          customRender: "name",
          filterDropdown: "inputSearch",
          filterIcon: "filterIcon"
        }
      },
      {
        title: "Kullanıcı Email",
        dataIndex: "mail",
        sorter: true,
        width: "20%",
        slots: {
          customRender: "mail",
          filterDropdown: "inputSearch",
          filterIcon: "filterIcon"
        }
      },
      {
        title: "Kullanıcı Telefon",
        dataIndex: "phone",
        width: "20%",
        slots: {
          customRender: "phone",
          filterDropdown: "inputSearch",
          filterIcon: "filterIcon"
        }
      },
      {
        title: "Kullanıcı Departman",
        dataIndex: "department",
        width: "20%",
        slots: {
          customRender: "department",
        }
      },
      {
        title: "Departman Yönetici",
        dataIndex: "departmentSuper",
        width: "20%",
        slots: {
          customRender: "departmentSuper",
        }
      }
    ];
    if (this.authUser.departmentSuperUser)
      this.columns.push({title: "İşlemler", fixed: "right", slots: {customRender: "action"}})
  }
};
</script>
